import backendIcon from './images/backend.svg';
import computerAutomationIcon from './images/computer-automation.svg';
import globeNetworkIcon from './images/globe-network.svg';
import networkTestingIcon from './images/network-testing.svg';
import pluginMonitoringIcon from './images/plugin-monitoring.svg';
import processTestingIcon from './images/process-testing.svg';

export const pythonDevelopersData = [
  {
    icon: computerAutomationIcon,
    altName: 'QA Automation',
    text: 'QA automation - E2E (end-to-end) testing of network solutions (from low-level to SDN controllers)',
  },
  {
    icon: processTestingIcon,
    altName: 'Performance testing',
    text: 'Performance testing of network solutions (traffic generation, TRex)',
  },
  {
    icon: networkTestingIcon,
    altName: 'Network testing',
    text: 'Network \ntesting',
  },
  {
    icon: globeNetworkIcon,
    altName: 'Network automation',
    text: 'Network automation',
  },
  {
    icon: backendIcon,
    altName: 'Backend applications',
    text: 'Backend applications based on Flask and Django',
  },
  {
    icon: pluginMonitoringIcon,
    altName: 'Monitoring tools',
    text: 'Plugins to infrastructure monitoring tools',
  },
];
