import downloadImage from './download-image.png';

import * as styles from './payload.module.scss';

export const sectionData = {
  headerTitle: 'Python development services',
  trustedByTitle: 'What our Clients say about us',
};

export const letsTalkData = {
  firstHeading: 'Still unsure whether Python is the right language for your project?',
  buttonTitle: 'Contact our Python experts',
  id: 'python-lets-talk',
  downloadImage: downloadImage,
  buttonLink: '#contact',
  classNames: {
    section: styles.section,
    firstRow: styles.firstRow,
    descriptionContainer: styles.textWrapper,
    imageContainer: styles.imageContainer,
    buttonWithLink: styles.buttonWithLink,
  },
};
