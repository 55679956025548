import React from 'react';

import prototypIcon from '@images/python-development/prototyp.svg';
import graphIcon from '@images/rust-development/we-help-you/graph.svg';
import crossPlatformIcon from '@images/rust-development/why-use-rust/cross-platform.svg';

export const whyUsePythonData = {
  firstParagraph: (
    <>
      Python is a high-level, interpreted, and general-purpose programming language with a focus on good code
      readability. Python has a lot of built-in data structures and combines dynamic typing and dynamic binding that
      allows faster code testing and speeds up the whole development process. With Python you can accelerate your
      product&apos;s time-to-market.
    </>
  ),
  secondParagraph: (
    <>
      The Python programming language is easily adjustable, powerful, and easy-to-use. However, can that help solve your
      business problems?
    </>
  ),
  thirdParagraph: 'This is how Python can benefit your software development project:',
  methods: [
    {
      src: prototypIcon,
      title: 'Great for prototyping',
      subtitle:
        'Python is a great solution for prototyping, testing and debugging tools. It has easy to implement static and dynamic data analysis and contains infrastructure for parsing and handling programs as trees.',
    },
    {
      src: crossPlatformIcon,
      title: 'Compatible with various systems and platforms',
      subtitle:
        'Python supports different operating systems and allows running the same code on numerous platforms without recompilation. No recompilation provides for easier code changes without compromising on development time.',
    },
    {
      src: graphIcon,
      title: 'Python makes complex software development simple',
      subtitle:
        'As a general purpose language, Python can be used both for web and desktop applications. It also provides facilitation of data analysis – thanks to dedicated libraries and APIs, data can be presented more clearly and attractively.',
    },
  ],
};
