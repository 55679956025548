export const checkWhatWeWroteData = {
  title: 'Check what we wrote about Python',
  paragraphOne:
    'For over 11 years, we have been working with Python. We want to share more examples of our projects – there are so many that we cannot include them all in one place.',
  paragraphTwo:
    'If you want more information about Python, you can check out our other articles where we compare Python to other programming languages, or our use cases which offer more real-life examples of how we helped our clients.',
  articleList: [
    {
      title: 'Go vs. Python - main differences overview',
      targetUrl: '/blog/go-vs-python/',
    },
    {
      title: 'Connecting different VPC/VNets and extending on-premise resources to multiple clouds',
      targetUrl: '/case-study/connecting-vpc-vnets-and-extending-on-premise-resources-to-multiple-clouds/',
    },
    {
      title: 'Using ONOS SDN controller with P4-programmable smartNICs to offload VNFs',
      targetUrl: '/case-study/using-onos-sdn-controller-with-p4-programmable-smartnics-to-offload-vnfs/',
    },
    {
      title: 'An automatic data center and low-level network infrastructure management solution',
      targetUrl: '/case-study/automatic-data-center-and-low-level-network-infrastructure-management-solution/',
    },
    {
      title: 'Building a web application for network monitoring in heterogeneous environments',
      targetUrl: '/case-study/building-web-application-for-network-monitoring-in-heterogeneous-environments/',
    },
    {
      title: 'Building CI/CD and testing for a full-stack monitoring and alerting service',
      targetUrl: '/case-studies/',
    },
  ],
};
