import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { SingleBoxDescriptive } from '@commons/single-box-descriptive/SingleBoxDescriptive';

import { pythonDevelopersData } from './payload';

import * as styles from './python-developers.module.scss';

export const PythonDevelopers = () => {
  return (
    <StandardSection
      classNames={{
        title: styles.title,
        section: styles.section,
        innerWrapper: styles.innerWrapper,
      }}
      title="Our Python Developers will help you with:"
      id="python-developers"
    >
      <div className={styles.boxesContainer}>
        {pythonDevelopersData.map((singleBox, index) => (
          <SingleBoxDescriptive
            textContent={singleBox.text}
            className={styles.singleBox}
            src={singleBox.icon}
            alt={singleBox.altName}
            key={index}
          />
        ))}
      </div>
    </StandardSection>
  );
};
