import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ParagraphsWithBoxes } from '@pages-impl/golang-development/common/ParagraphsWithBoxes';

import { whyUsePythonData } from './payload';

import * as styles from './why-use-python.module.scss';

export const WhyUsePython = () => {
  return (
    <StandardSection
      title="Why use the Python programming language for your project?"
      className={styles.section}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
      id="why-use-python"
    >
      <ParagraphsWithBoxes
        {...whyUsePythonData}
        classNames={{ boxesContainer: styles.boxesContainer, singleBoxDescription: styles.singleBoxDescription }}
      />
    </StandardSection>
  );
};
