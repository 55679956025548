import React from 'react';
import downloadImage from '@images/python-development/about-us.png';

export const paragraphsData = {
  firstParagraph: (
    <>
      Developing complex network solutions can be challenging, but not with top-notch Python developers onboard. The
      Python programming language allows the{' '}
      <span className="bold-text">building of long-lasting, scalable, and reliable network applications.</span>
    </>
  ),
  secondParagraph: (
    <>
      Since 2011, CodiLime&apos;s Python developers have been helping businesses all over the world to bring software
      development to the next level with their expert skills and knowledge.
    </>
  ),
  buttonLabel: 'Talk with our Python experts',
  imageOnRight: downloadImage,
};
