import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { Button } from '@commons/button/Button';

import { checkWhatWeWroteData } from './payload';

import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './check-what-we-wrote.module.scss';

export const CheckWhatWeWrote = () => {
  const { title, paragraphOne, paragraphTwo, articleList } = checkWhatWeWroteData;

  return (
    <StandardSection
      title={title}
      id="about-python"
      classNames={{
        title: styles.sectionTitle,
        innerWrapper: styles.innerWrapper,
      }}
    >
      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          <p className={styles.paragrapgh}>{paragraphOne}</p>
          <p className={styles.paragrapgh}>{paragraphTwo}</p>
          <StaticImage src="./images/python-computer.svg" alt="Python" placeholder="none" />
        </div>
        <div className={styles.column}>
          {articleList.map((article, index) => (
            <Button
              className={styles.articleButton}
              href={article.targetUrl}
              label={article.title}
              key={index}
              flexed
            />
          ))}
        </div>
      </div>
    </StandardSection>
  );
};
