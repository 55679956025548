import React from 'react';

import { AboutUsSection } from '@pages-impl/shared/about-us-section/AboutUsSection';

import { paragraphsData } from './payload';

import * as styles from './about-us.module.scss';

export const AboutUs = () => {
  return (
    <AboutUsSection
      {...paragraphsData}
      buttonHref="#contact"
      imageAltText="talk with our experts"
      spaceOnMobile
      classNames={{ customStylesButton: styles.customStylesButton }}
    />
  );
};
