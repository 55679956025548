import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';
import { LetsTalkSection } from '@pages-impl/shared/lets-talk-section/LetsTalkSection';
import { AboutUs, WhyUsePython, OurApproach, PythonDevelopers, CheckWhatWeWrote } from '@pages-impl/python-development';
import { TrustedBySection } from '@commons';

import featuredImage from '@images/header-images/python-development-services.jpg';

import { sectionData, letsTalkData } from '@pages-impl/python-development/payload';

import headerImage from '@images/header-images/codilime_python_header.jpg';

import * as styles from './python-development.module.scss';

export default function Python(props) {
  const { headerTitle, trustedByTitle } = sectionData;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    '@id': 'https://codilime.com/services/python-development/',
    headline: 'Python development services',
    url: 'https://codilime.com/services/python-development/',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Why use Python for your project?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Python has a lot of built-in data structures and combines dynamic typing and dynamic binding that allows faster code testing and speeds up the whole development process. With Python, you can accelerate your product's time-to-market.",
        },
      },
      {
        '@type': 'Question',
        name: 'How can our developers help you with your project?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'If you are looking for a reliable technology partner for your project, we are here for you. We can help you with: QA automation, performance testing, network testing, network automation, backend applications, plugins to infrastructure monitoring tools.',
        },
      },
    ],
  };

  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Boost your Python project with us"
      oldPage
      {...props}
    >
      <SEO
        title="Python Development Services Company - CodiLime"
        description="Want to build a project in Python? Choose an experienced strategic partner. We've been developing software for tech-driven businesses since 2013."
        featuredImage={featuredImage}
        schemaMarkup={schema}
      />
      <Header
        title={headerTitle}
        showImageOnMobile
        src={headerImage}
        srcSVG
        srcSmall="codilime_golang_header_small.png"
        displayTopBanner={false}
        className={styles.header}
      />
      <AboutUs />
      <WhyUsePython />
      <LetsTalkSection {...letsTalkData} />
      <PythonDevelopers />
      <OurApproach />
      <TrustedBySection className={styles.trustedBySection} title={trustedByTitle} />
      <CheckWhatWeWrote />
    </Layout>
  );
}
